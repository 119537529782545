import "../PopUp/TrackerMarkerPopUp.module.scss";
import { useSelector } from "react-redux";

import reduxStore from "../../reduxStore";
import { toggle } from "../../trackerWindowsSlice";
import { set as setHoveredTracker } from "../../redux/slices/hoveredTracker";

import { useBooking } from "../CheckinsPage/hooks";
import { useAppDispatch } from "../../hooks/reduxHooks";
import styles from "../PopUp/TrackerMarkerPopUp.module.scss";
//import Image from 'next/image'

export default function TrackerInfoTabs(props) {
  const { tracker, config, categories } = props;
  const speedUnit = useSelector((state) => state.trackerSettings.speedUnit);

  const toggleTrackerWindow = (val) => reduxStore.dispatch(toggle(val));

  const trackView = useSelector((state) => state.currentTrack);
  const fenceArrayLength = tracker.Track.length;
  let trackTest = true;
  if (fenceArrayLength > 0) {
    trackTest = tracker.Track[fenceArrayLength - 1].TrackPrefix === trackView;
  }
  if (trackView === config.sitePrefix || trackView === "SITE") {
    trackTest = true;
  }
  // console.log(tracker.metadata.trackData.properties.UniqueName);

  let icon = categories[tracker.Category]?.icon
    ? categories[tracker.Category].icon
    : "GreenDiamond.png";

  if (tracker.alarmTime > tracker.alarmAckTime) {
    icon = "RedDiamond.png";
  }
  if (icon === undefined) {
    icon = categories.Default.icon;
  }
  let iconUrl = config.url.API + "/iconFiles/" + icon;

  let track = config.sitePrefix;
//   console.log(track);

  let geofence = "Off Track";
  if (tracker?.Track.length > 0) {
    geofence = tracker.Track[0].Geofence;
    track = tracker.Track[0].TrackPrefix;
  }

  // We fetch the booking so it's loaded into the cache before the user open the tracker window
  const bookingData = useBooking(tracker.ID);
  const dispatch = useAppDispatch();

  // Speed calculation
  let speedDisplay = ""; // Initialize as empty string

  // If tracker.Status is not 'Fail' or 'fail', display the speed
  if (tracker.Status !== "Fail" && tracker.Status.toLowerCase() !== "fail" && tracker.Status !== "GPS-Fail") {
    const speedValue = parseFloat(tracker.Speed) || 0;
    const formattedSpeed = speedValue.toFixed(2);
    speedDisplay = `${formattedSpeed} ${speedUnit}`;
  }
 
  return (
    <>
      {trackTest && (
        <tr
          key={tracker.IMEI}
          onMouseEnter={() => dispatch(setHoveredTracker(tracker.IMEI))}
          onMouseLeave={() => dispatch(setHoveredTracker(""))}
          onClick={() => {
            // I noticed some inconsistency with the toggle method, so we're manually flipping the value
            toggleTrackerWindow(tracker.IMEI);
          }}
        >
          <td>
            {JSON.stringify(tracker.ID).replaceAll('"', "")}{" "}
            <img
              title={tracker.Category}
              className="TrackerTabIcons"
              width={16}
              height={16}
              src={`${iconUrl}`}
              alt={"tracker tag icons"}
            />
          </td>
          <td>{tracker.Status}</td>
          <td>{tracker.Category}</td>
          <td>{track}</td>
          <td>{tracker.BatteryStatus}</td>
          <td>{tracker.SatCnt}</td>
          <td>{tracker.HDOP}</td>
          <td>{speedDisplay}</td>
        </tr>
      )}
    </>
  );
}
