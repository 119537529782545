import React from 'react';
import styles from './Header.module.scss';

function SelectedTrackTab({ track }) {
  return (
    <div className={styles.selectedTrackTab}>
      {track} {/* Ensure this displays the correct track name */}
    </div>
  );
}

export default SelectedTrackTab;