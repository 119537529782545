import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import maplibre from "maplibre-gl";
import Map, {
  FullscreenControl,
  Layer,
  LngLatBoundsLike,
  NavigationControl,
  Source,
  useMap,
} from "react-map-gl";
import TrackerMarker from "./Marker/TrackerMarker.js";
import BarrierMarker from "./Marker/BarrierMarker";
import DirectionMarker from "./Marker/DirectionMarker";
import axios from "axios";
//import directions from "../../geojson/directions.json";

import selectedTrackContext from "../../selectedTrackContext";
import selectedTrackViewContext from "../../selectedTrackViewContext";
import allTrackViewsContext from "../../allTrackViewsContext";
import goToTrackViewContext from "../../goToTrackViewContext";

import "mapbox-gl/dist/mapbox-gl.css";
import useFullscreenStatus from "../../useFullscreenStatus";
import { useAppSelector } from "../../hooks/reduxHooks";
import useSWR from "swr";
import { Typography } from "@mui/material";
import getConfig from '../../config/config';

let config = getConfig();

function SelectTrack() {
  const [selectedTrackView, setSelectedTrackView] = useContext(
    selectedTrackViewContext
  );
  const { current: map } = useMap();

  useEffect(() => {
    if (map) {
      const handleMove = () => {
        const { lng, lat } = map.getCenter();
        const zoom = map.getZoom();
        const bearing = map.getBearing();
        setSelectedTrackView([lng, lat, zoom, bearing]);
      };

      map.on("move", handleMove);

      return () => {
        map.off("move", handleMove);
      };
    }
  }, [map, setSelectedTrackView]);

  return null;
}

function GoToTrackView() {
  const [allTrackViews] = useContext(allTrackViewsContext);
  const [selectedTrack] = useContext(selectedTrackContext);
  const { current: map } = useMap();

  useEffect(() => {
    if (map && allTrackViews[selectedTrack]) {
      const trackView = allTrackViews[selectedTrack];
      map.flyTo({
        center: [trackView.longitude, trackView.latitude],
        zoom: trackView.zoom,
        bearing: trackView.bearing,
        pitch: 0,
      });
    }
  }, [map, selectedTrack, allTrackViews]);

  return null;
}

function GeofenceDisplay({
  data,
  geofences,
}: {
  data: any[];
  geofences: GeoJSON.FeatureCollection;
}) {
  const activeIds = data;
  const filteredGeofences = geofences.features.filter((item) =>
    activeIds.includes(item?.properties?.Autocad_layer)
  );

  const geoJsonData: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: filteredGeofences,
  };

  return (
    <Source type="geojson" data={geoJsonData}>
      <Layer
        id="layerStylePolygon"
        type="fill"
        paint={{ "fill-color": "#FFA500", "fill-opacity": 0.4 }}
        filter={["==", "$type", "Polygon"]}
      />
      <Layer
        id="layerStyleLine"
        type="line"
        layout={{ "line-join": "round", "line-cap": "round" }}
        paint={{
          "line-color": "#FF0000",
          "line-width": 8,
          "line-opacity": 0.4,
        }}
      />
    </Source>
  );
}

export default function MapElement({ viewState, setViewState, config, ...props }) {
  const [selectedTrack, setSelectedTrack] = useContext(selectedTrackContext);
  const [allTrackViews] = useContext(allTrackViewsContext);

  const [directions, setDirections] = useState(null);

  const [geofences, setGeofences] = useState<GeoJSON.FeatureCollection>();

  const categories = useAppSelector((state) => state.categories);
  const barrierData = useAppSelector((state) => state.barriers);
  const dirAndMax = useAppSelector((state) => state.dirmax);
  const barriersState = useAppSelector((state) => state.plcBarriersEvent);
  const trackers = useAppSelector((state) => state.trackers);
  const trackView = useAppSelector((state) => state.currentTrack);
  const isReplay = useAppSelector((state) => state.replay.isReplay);

  const markers = useMemo(
    () =>
      Object.values(trackers).map((tracker, index) => (
        <TrackerMarker
          config={config}
          key={index}
          tracker={tracker}
          categories={categories}
        />
      )),
    [trackers, categories, config]
  );

  useEffect(() => {
    if (allTrackViews[trackView]) {
      setViewState({
        longitude: allTrackViews[trackView].longitude,
        latitude: allTrackViews[trackView].latitude,
        zoom: allTrackViews[trackView].zoom,
        bearing: allTrackViews[trackView].bearing,
        pitch: 0,
        padding: { top: 0, bottom: 0, left: 0, right: 0 },
      });
      setSelectedTrack(trackView);
    }
  }, [trackView, allTrackViews, setSelectedTrack]);

  useEffect(() => {
    const fetchGeofences = async () => {
      try {
        const response = await axios.get(`${config.url.API}/geofence`);
        const sanitizedData = sanitizeJSON(response.data);
        const data = JSON.parse(sanitizedData);
        setGeofences(data);
      } catch (error) {
        console.error("Error fetching geofences:", error);
      }
    };

    fetchGeofences();
  }, [props.geofenceOpen, config.url.API]);

  const fullscreenRef = useRef(document.documentElement);
  const [isFullscreen, setFullscreen] = useFullscreenStatus(fullscreenRef);
  const [areDirectionMarkersVisible, setAreDirectionMarkersVisible] =
    useState(!isFullscreen);

  useLayoutEffect(() => {
    setAreDirectionMarkersVisible(!isFullscreen);
  }, [isFullscreen]);

  useEffect(() => {
    const handleKeydown = (e) => {
      if (e.key === "F11") {
        e.preventDefault();
        setFullscreen();
      }
    };

    document.addEventListener("keydown", handleKeydown);
    return () => document.removeEventListener("keydown", handleKeydown);
  }, [setFullscreen]);

  const { data: style, isValidating } = useSWR("/map/style.json", {
    fallbackData: "/map/style.json",
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false,
    revalidateOnMount: false,
  });

  useEffect(() => {
    const directionsUrl = `https://${config.host}/map/directions.json`;

    fetch(directionsUrl)
      .then((response) => response.json())
      .then((data) => setDirections(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  // Ensure bounds are correctly ordered and within valid range
  const bounds: LngLatBoundsLike = [
    [
      Math.min(parseFloat(config.swBounds.split(",")[0]), parseFloat(config.neBounds.split(",")[0])),
      Math.min(parseFloat(config.swBounds.split(",")[1]), parseFloat(config.neBounds.split(",")[1]))
    ],
    [
      Math.max(parseFloat(config.swBounds.split(",")[0]), parseFloat(config.neBounds.split(",")[0])),
      Math.max(parseFloat(config.swBounds.split(",")[1]), parseFloat(config.neBounds.split(",")[1]))
    ]
  ];
//   console.log("Map bounds set to:", bounds);
  // Parsing track checks based on the updated format from config.ts
  const parsedTrackChecks = config.trackChecks ? config.trackChecks.split(';').map(check => {
    const [trackId, coords] = check.split('|').map(item => item.trim());
    const [divisor, remainder] = coords.split(',').map(Number);
    return {
      trackId,
      divisor,
      remainder
    };
  }) : [];
//   console.log(parsedTrackChecks);
  if (isValidating) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Map
      mapLib={maplibre}
      reuseMaps={true}
      maxBounds={bounds}
      style={{ width: "100vw", height: `${window.innerHeight - 80}px` }}
      mapStyle={style}
      {...viewState}
      onMove={(evt) => setViewState(evt.viewState)}
      pitchWithRotate={false}
    >
      {geofences && <GeofenceDisplay data={props.data} geofences={geofences} />}
      {barrierData &&
        Object.entries(barrierData).map(([key, barrier]) => {
          if (!key.includes("_")) {
            return (
              <BarrierMarker
                config={config}
                key={key}
                name={key}
                barrier={barrier}
                state={barriersState[key]}
                isReplay={isReplay}
                trackData={dirAndMax ? dirAndMax[barrier.trackPrefix] : null}
              />
            );
          }
          return null;
        })}
      {markers}
      {directions && parsedTrackChecks.map(({ trackId, divisor, remainder }) =>
  directions[trackId] ? (
    //@ts-ignore
    directions[trackId].map((data, i) => {
      if (i % divisor !== remainder) {
        return null;
      }
      const { latitude, longitude, rotation } = data;
      return (
        <DirectionMarker
          key={`${trackId}-${i}`}
          latitude={latitude}
          longitude={longitude}
          allTrackViews={allTrackViews}
          rotation={rotation}
          direction={props.trackDirections[trackId]}
          track={trackId}
          config={config}
          visible={areDirectionMarkersVisible}
        />
      );
    })
  ) : null
)}
      <NavigationControl position="top-left" />
      <FullscreenControl position="top-right" />
      <SelectTrack />
      <GoToTrackView />
    </Map>
  );
}

// Helper function to sanitize JSON strings from geofence JSON
const sanitizeJSON = (jsonString) => {
  try {
    JSON.parse(jsonString);
    return jsonString;
  } catch (error) {
    console.error("Original JSON parsing error:", error);
    return jsonString.replace(/\\n/g, "").replace(/\\'/g, "'");
  }
};
