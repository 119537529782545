import React, { useState, useContext } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  Grid,
} from "@mui/material";
import {
  Home as HomeIcon,
  Menu as MenuIcon,
  LightbulbOutlined,
  MapOutlined,
  Replay,
  OpenInNew as OpenInNewIcon,
} from "@mui/icons-material";
import allTrackViewsContext from "../../allTrackViewsContext";
import selectedTrackContext from "../../selectedTrackContext";
import selectedTrackViewContext from "../../selectedTrackViewContext";
import goToTrackViewContext from "../../goToTrackViewContext";
import { useDispatch, useSelector } from "react-redux";
import { toggleControls as toggleReplayControls } from "../../redux/slices/replay";
import { postTrackView } from "../../utils/headerUtils";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { DraggableIFrame } from "../DraggablePopup/DraggablePopup";
import styles from "./Header.module.scss"; // Import the existing styles

function MenuIconButton(props) {
  const { config } = props;
  const permissions = useSelector((state) => state.permissions);
  const [allTrackViews, setAllTrackViews] = useContext(allTrackViewsContext);
  const [selectedTrack] = useContext(selectedTrackContext);
  const [selectedTrackView] = useContext(selectedTrackViewContext);
  const [goToTrackView, setGoToTrackView] = useContext(goToTrackViewContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const [showReports, setShowReports] = useState(false);
  const dispatch = useAppDispatch();
  const isReplay = useAppSelector((state) => state.replay.isReplay);

  return (
    <div className={styles.iconContainer}> {/* Add a container for the icons */}
      <IconButton
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
          const temp = allTrackViews;
          temp[selectedTrack].longitude = selectedTrackView[0];
          temp[selectedTrack].latitude = selectedTrackView[1];
          temp[selectedTrack].zoom = selectedTrackView[2];
          temp[selectedTrack].bearing = selectedTrackView[3];
          setAllTrackViews(temp);
          let track = selectedTrack;
          let lat = selectedTrackView[1];
          let long = selectedTrackView[0];
          let zoom = selectedTrackView[2];
          let bearing = selectedTrackView[3];
          let view = {
            [track]: {
              longitude: long,
              latitude: lat,
              zoom: zoom,
              bearing: bearing,
              maxPitch: 0,
            },
          };
          postTrackView(config, view).then();
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setGoToTrackView(goToTrackView + 1);
        }}
      >
        <HomeIcon />
      </IconButton>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
      >
        {!isReplay && (
          <MenuLink onClick={handleClose} href={config.url.DASHBOARD}>
            Grafana
          </MenuLink>
        )}
        {permissions?.canModifyConfig && !isReplay && (
          <MenuLink onClick={handleClose} href={config.url.CONFIG}>
            Config
          </MenuLink>
        )}
        {(permissions?.canModifyAlarms || permissions?.canAccessAlarms) && (
          <MenuLink onClick={handleClose} href={"/alarms"}>
            Alarms
          </MenuLink>
        )}
        {permissions?.canAccessBookings && (
          <MenuLink onClick={handleClose} href={"/checkins"}>
            Check-ins
          </MenuLink>
        )}
        <MenuLink onClick={handleClose} href={config.url.TRACK_STATUS}>
          Track Status
        </MenuLink>
        {permissions?.canAccessReports && !isReplay && (
          <MenuLink onClick={handleClose} href={config.url.REPORTS}>
            Reports
          </MenuLink>
        )}
        {config.lights === "true" && (
          <MenuItem
            onClick={() => {
              props.toggleLightPanel(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <LightbulbOutlined style={{ transform: "scale(.75)" }} />
            </ListItemIcon>
            <ListItemText>Lights</ListItemText>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            props.handleGeofence(!props.geofenceOpen);
            handleClose();
          }}
        >
          <ListItemIcon>
            <MapOutlined style={{ transform: "scale(.75)" }} />
          </ListItemIcon>
          <ListItemText>Geofences</ListItemText>
        </MenuItem>
        {permissions?.canModifyConfig && config.isQA && (
          <MenuItem
            onClick={() => {
              dispatch(toggleReplayControls(true));
              handleClose();
            }}
          >
            <ListItemIcon>
              <Replay style={{ transform: "scale(.75)" }} />
            </ListItemIcon>
            <ListItemText>Open Replay Controls</ListItemText>
          </MenuItem>
        )}
        <MenuItem>
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <a
              href={`https://${config.host}/vmapi/versions/versions.json`}
              rel={"noreferrer"}
              target={"_blank"}
            >
              <Typography variant={"subtitle2"}>
                vMonitor {config.version}
              </Typography>
            </a>
          </Grid>
        </MenuItem>
      </Menu>
      {showReports && (
        <DraggableIFrame
          url={config.url.REPORTS}
          title={"Reports"}
          open={showReports}
          onClose={() => setShowReports(false)}
          autosize={true}
        />
      )}
    </div>
  );
}

function MenuLink(props) {
  return (
    <a href={props.href} target={"_blank"} rel="noreferrer">
      <MenuItem onClick={props.onClick}>
        <ListItemIcon>
          <OpenInNewIcon style={{ transform: "scale(.75)" }} />
        </ListItemIcon>
        <ListItemText>{props.children}</ListItemText>
      </MenuItem>
    </a>
  );
}

export default MenuIconButton;
