import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { Circle, Error } from "@mui/icons-material";
import { useSelector } from "react-redux";
import ServicesStatus from "./ServicesStatus";

function StatusList(props) {
  const statuses = props.statuses;
  const iconOK = <Circle style={{ color: "#0ec416" }} />;
  const iconFail = <Error color={"error"} />;
  const status = useSelector((state) => state.status);
  const permissions = useSelector((state) => state.permissions);

  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <nav aria-label="main mailbox folders">
        <List>
          <ListItem>
            <ListItemText primary="Service Status" />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemIcon>{status.websocket ? iconOK : iconFail}</ListItemIcon>
            <ListItemText primary="Websocket" />
          </ListItem>
          <ListItem>
            <ListItemIcon>{status.webui ? iconOK : iconFail}</ListItemIcon>
            <ListItemText primary="Web UI Server" />
          </ListItem>
        </List>
        {permissions.textid === "sysAdmin" && (
          <ServicesStatus statuses={statuses} dockerNodes={props.dockerNodes} />  
        )}
      </nav>
    </Box>
  );
}

export default StatusList;
