import {
  Component,
  createRef,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./TrackerMarkerPopUp.module.scss";
import axios from "axios";
import moment from "moment";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { DraggableWindow } from "../DraggablePopup/DraggablePopup";
import { remove, update } from "../Map/Marker/trackerSlice";
import { toggle } from "../../trackerWindowsSlice";
import store from "../../reduxStore";
import reduxStore from "../../reduxStore";
import { BookingForm } from "../../../pages/booking";
import { useBooking, useSuggestions, useTracks } from "../CheckinsPage/hooks";
import { useAppSelector } from "../../hooks/reduxHooks";
import useSWR, { mutate } from "swr";
import { Tracker } from "../../TrackerWindows";
import useResizeObserver from "@react-hook/resize-observer";
import { useSelector } from 'react-redux';

function BookingWindow(props) {
  const { config, vtuid, suggestions, tracks, booking, isReplay } = props;

  // let booking = props.booking
  // const query = booking?.id >= 0 ? `?id=${booking.id}` : `?vtuid=${vtuid}`
  // if(!(props.booking?.id > 0)) {
  //     booking = getEmptyBooking(vtuid)
  // }
  // console.log(booking)
  // console.log(props)
  window.addEventListener("message", (e) =>
    e.data === "done" ? props.onClose() : null
  );
  const form = (
    <BookingForm
      action={"View Check-in"}
      suggestions={suggestions}
      tracks={tracks}
      booking={booking}
      config={config}
      limitEditing={true}
      vtuid={vtuid}
      isReplay={isReplay}
    />
  );
  // return <DraggableIFrame url={window.location.href + config.url.BOOKING_ID + query} title={`Booking Window - ${vtuid}`} open={props.open ?? false} onClose={props.onClose} width={550} />
  return (
    <DraggableWindow
      defaultPosition={{ x: 100, y: -200 }}
      open={props.open ?? false}
      onClose={props.onClose}
      height={window.screen.availHeight - 50}
      title={`${vtuid} - Booking`}
      popupToolbar={false}
    >
      {/*<iframe src={window.location.href + config.url.BOOKING_ID + query} height={window.screen.availHeight-120} width={480} />*/}
      {form}
    </DraggableWindow>
  );
}

async function resetAlarmStates(id, config) {
  const url = `${config.url.TRACKERS_API}/resetAlarms/` + id;
  const data = await axios.get(url);
}

interface MarkerPopupProps {
  tracker: Tracker;
  lat: string;
  lon: string;
  name: string;
  onClosed: () => any;
  config: any;
  open: boolean;
}

export default function MarkerPopup(props: MarkerPopupProps) {
  // console.log(styles)
  const { tracker, lat, lon, name, onClosed, config, open } = props;
  const [showPopup, setShowPopup] = useState(false);
  const [expanded, setExpanded] = useState(false);
  // const [loading, setLoading] = useState(true)
  // const [alarmsList, setAlarmsList] = useState([])
  // const [submitting, setSubmitting] = useState(true)
  const replayConfig = useAppSelector((state) => state.replay);

  const trackerData = useBooking(tracker.ID, replayConfig.isReplay);
  const alarmsList = trackerData.alarmsList;
  // const [trackerData, setTrackerData] = useState()
  // console.log(trackerData)

  // const trackerWindowVisible = useSelector((store) => store.trackerWindow)
  const toggleTrackerWindow = (val) => reduxStore.dispatch(toggle(val));

  let track = config.sitePrefix;
  let geofence = "Off Track";
  if (tracker?.Track.length > 0) {
    geofence = tracker.Track[0].Geofence;
    track = tracker.Track[0].TrackPrefix;
  }
  if (tracker?.Approach.length > 0) {
    geofence = tracker.Approach[0].Geofence;
  }

  // setInterval(()=>{axios.get(`${config.url.BOOKING_API}/${tracker.ID}`).then(res=>{setAlarmsList(res.data.alarmsList);})},1500)

  // const getData = useCallback(() => {
  //     axios.get(
  //         `${config.url.BOOKING_API}/${tracker.ID}`
  //     ).then(data => {
  //         setAlarmsList(data.data.alarmsList)
  //         setLoading(false)
  //         setTrackerData(data.data)
  //         return data.data
  //     })
  // }, [tracker?.alarmTime])

  useEffect(() => {
    // console.log(tracker?.alarmTime, tracker?.alarmAckTime)
    mutate("/api/booking/" + tracker.ID).catch(console.error);
    // axios.get(
    //     `${config.url.BOOKING_API}/${tracker.ID}`
    // ).then(data => {
    //     setAlarmsList(data.data.alarmsList)
    //     // setLoading(false)
    //     setTrackerData(data.data)
    // }).then(() => setSubmitting(false))
  }, [tracker?.alarmTime, tracker?.alarmAckTime]);

  const permissions = useAppSelector((state) => state.permissions);

  // console.log(trackerData)

  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  useEffect(() => {
    // @ts-ignore
    setHeight(contentRef.current?.clientHeight);
    // @ts-ignore
    setWidth(contentRef.current?.clientWidth);
  }, [contentRef.current]);
  useResizeObserver(contentRef, (data) => {
    setHeight(data.contentRect.height);
    setWidth(data.contentRect.width);
    console.log(data);
  });

  function deleteFromMap(imei) {
    // console.log(imei);
    toggleTrackerWindow(imei);
    store.dispatch(remove(imei));
  }

  //create HH:DD:SS time in local time from epoch time stamp
  function createTime(epochTimeStampMs) {
    if (Number.isNaN(epochTimeStampMs)) {
      return "";
    } else {
      let offset = new Date().getTimezoneOffset();
      let ms = offset * 60 * 1000;
      let newTime = epochTimeStampMs - ms;
      let newDate = new Date(newTime);
      return (
        pad(newDate.getUTCHours()) +
        ":" +
        pad(newDate.getMinutes()) +
        ":" +
        pad(newDate.getSeconds())
      );
    }
  }

  function pad(num) {
    if (num.length < 2) {
      return "0" + num;
    } else {
      return num;
    }
  }

  const suggestions = useSuggestions();
  const tracks = useTracks({ tracks: [] });

//   console.log(width);

  const speedUnit = useAppSelector((state) => state.trackerSettings.speedUnit);

  let speedDisplay = ''; // Initialize as empty string
  if (tracker.Status.toLowerCase() !== 'fail') {
    const speedValue = parseFloat(tracker.Speed) || 0;
    speedDisplay = `${speedValue.toFixed(2)} ${speedUnit}`;
  }
  // If tracker.Status is 'Fail', speedDisplay remains an empty string

  console.log("speedUnit:", speedUnit);

  return (
    /*
                          <Popup latitude={lat} longitude={lon} closeOnClick={false} onClose={() => onClosed(false)} >
                              Name: { name } <br />
                              Location: { lat }, { lon }<br />
                              Status: {tracker.Status}<br />
                              Track: {tracker.TrackFences[fenceArrayLength - 1].object.feature.properties.TrackPrefix} <br />
                              Geofence: {tracker.TrackFences[fenceArrayLength - 1].object.feature.properties.UniqueName} <br />
                              Speed: {tracker.Speed}<br />
                        </Popup >
                          */

    // check if tracker.TrackFences[fenceArrayLength]
    <>
      <DraggableWindow
        offset={{ x: 20, y: 200 }}
        title={"Tracker Info"}
        onClose={onClosed}
        open={open}
      >
        <div className={styles.markerContainer} ref={contentRef}>
          <div className={styles.trackerStatusWindowHeader}>
            <h3 className={styles.headerTitle}>{name}</h3>
            <button
              style={{ width: 15, height: 15 }}
              className={styles.headerButton}
              onClick={
                expanded ? () => setExpanded(false) : () => setExpanded(true)
              }
            >
              {expanded ? (
                <ArrowDropUpIcon></ArrowDropUpIcon>
              ) : (
                <ArrowDropDownIcon></ArrowDropDownIcon>
              )}{" "}
            </button>
          </div>

          {trackerData && (
            <div className={styles.markerInfoContainer}>
              <ul>
                <li>
                  <div>
                    <p>Tracker Status </p>
                    <p>{tracker?.Status}</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>Battery Status</p>
                    <p>{tracker?.BatteryStatus}</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>Category</p> <p>{tracker?.Category}</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>Company</p>
                    <p>{trackerData?.company}</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>Department</p>
                    <p>{trackerData?.department}</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>Driver</p>
                    <p>{trackerData?.driver}</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>Booked By</p>
                    <p>{trackerData?.bookedby}</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>Reg No</p>
                    <p>{trackerData?.regno}</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>Test</p>
                    <p>{trackerData?.test}</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>Comments</p>
                    <p>{trackerData?.comments}</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>Speed</p>
                    <p>{speedDisplay}</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>Track</p>
                    <p>{track}</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>Track Entry Time</p>
                    <p>{createTime(tracker?.currentTrackTime)}</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>Geo-Fence</p>
                    <p>{geofence}</p>
                  </div>
                </li>
              </ul>
            </div>
          )}
        </div>

        <ExpandedWindow
          open={expanded}
          alarms={trackerData?.alarms}
          internal={trackerData?.internal}
          alarmsList={alarmsList}
          lat={lat}
          lon={lon}
          processTime={tracker?.processTime}
          battery={tracker?.BatteryStatus}
        />
        <div className={styles.bottomContainer}>
          {permissions.canAccessBookings && (
            <button
              disabled={!(trackerData?.id ?? 0 > 0)}
              onClick={
                () => setShowPopup(true)
                // () => axios({
                //     method: 'get',
                //     url: config.url.TRACKER_BOOKING + tracker.ID,
                //     headers: {},
                //     data: {}
                //
                // }).then(() => setShowPopup(true)
              }
            >
              Booking
            </button>
          )}
          <button onClick={() => resetAlarmStates(name, config)}>
            Reset Alarms
          </button>
          <button onClick={() => deleteFromMap(tracker?.IMEI)}>Remove</button>
        </div>
      </DraggableWindow>
      <BookingWindow
        booking={Object.fromEntries(
          Object.entries(trackerData).filter(([k, v]) => k !== "alarmsList")
        )}
        config={config}
        vtuid={name}
        open={showPopup}
        suggestions={suggestions}
        tracks={tracks}
        isReplay={replayConfig.isReplay}
        features={{ width, height }}
        onClose={() => setShowPopup(false)}
      />
    </>
  );
}

class ExpandedWindow extends Component {
  constructor(props) {
    super(props);
    this.alarmsRef = createRef();
    this.props = props;
  }
  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (this.alarmsRef.current !== null) {
      const list = this.alarmsRef.current;
      return list.scrollHeight - list.scrollTop;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log(this.alarmsRef.current)
    if (snapshot !== null && this.alarmsRef.current !== null) {
      const list = this.alarmsRef.current;
      list.scrollTop = list.scrollHeight - snapshot;
    }
  }

  alarmsRef: RefObject<any>;
  props: any;

  render() {
    return (
      this.props.open && (
        <>
          <div className={styles.markerContainer}>
            <div className={styles.markerInfoContainer}>
              <ul>
                <li>
                  <div>
                    <p>Alarms</p>
                    <p>
                      {this.props.internal
                        ? "Internal"
                        : this.props.alarms
                          ? "Enabled"
                          : "Disabled"}
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>Lat, Lon</p>
                    <p>
                      {this.props.lat} , {this.props.lon}
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>Processing Time</p>
                    <p>{this.props.processTime}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.alarmsHeaderContainer}>
            <div>Time</div>
            <div>Alert</div>
            <div>Track</div>
          </div>

          <div className={styles.alarms} ref={this.alarmsRef}>
            <AlarmList alarmsList={this.props.alarmsList} />
          </div>
        </>
      )
    );
  }
}

function AlarmList(props) {
  const { alarmsList } = props;
  const [alarms, setAlarms] = useState(props.alarmsList);

  useMemo(() => {
    setAlarms((oldList) => alarmsList ?? oldList);
  }, [alarmsList]);

  return (
    <>
      {alarms?.map((o, i) => (
        <div className={styles.alarmsContainer} key={`alarm${i}`}>
          <div
            className={
              o.action == "Ack"
                ? styles.greyAlarmsBlock
                : o.severity == "Medium"
                  ? styles.orangeAlarmsBlock
                  : styles.redAlarmsBlock
            }
          >
            {moment(o.time).local().format("MMMM Do YYYY, h:mm a")}
          </div>
          <div
            className={
              o.action == "Ack"
                ? styles.greyAlarmsBlock
                : o.severity == "Medium"
                  ? styles.orangeAlarmsBlock
                  : styles.redAlarmsBlock
            }
          >
            {" "}
            {o.alert}
          </div>
          <div
            className={
              o.action == "Ack"
                ? styles.greyAlarmsBlock
                : o.severity == "Medium"
                  ? styles.orangeAlarmsBlock
                  : styles.redAlarmsBlock
            }
          >
            {" "}
            {o.track}
          </div>
        </div>
      ))}
    </>
  );
}
